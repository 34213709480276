<template>
  <div :class="margin">
    <UFormGroup
      :label="label"
      :ui="labelUi"
      :name="id"
      :size="size"
    >
      <USelectMenu
        :id="id"
        ref="selectMenu"
        :model-value="modelValue"
        :name="id"
        :multiple="multiple"
        v-bind="$attrs"
        :ui="ui"
        :popper="{ placement: 'bottom-start', offsetDistance: 5 }"
        :ui-menu="{
          container: containerUi,
        }"
        :searchable-placeholder="$t('search')+'...'"
        @open="onOpen"
        @update:model-value="($event: string | Array<string> | object | Array<object> | number | Array<number>) => $emit('update:model-value', $event)"
      >
        <template #label>
          <slot name="label" />
        </template>
        <template #option="{ option }">
          <slot
            name="option"
            :option="option"
          />
        </template>
        <template #trailing>
          <UIcon
            :name="isClearable ? 'i-mdi-close' : 'i-mdi-chevron-down'"
            class="size-4 shrink-0 text-gray-400 dark:text-gray-500"
          />
        </template>
      </USelectMenu>
    </UFormGroup>
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    id: string
    modelValue?: string | Array<string> | object | Array<object> | number | Array<number>
    label?: string
    margin?: string
    multiple?: boolean
    customClasses?: string
    containerUi?: string
    ui?: object
    labelUi?: object
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
    noFocus?: boolean
    clearable?: boolean
  }>(),
  {
    multiple: false,
    label: '',
    margin: 'mb-2',
    containerUi: 'z-40 min-w-36',
    customClasses: '',
    size: 'sm',
    noFocus: false,
    clearable: false,
    ui: () => ({}),
    labelUi: () => ({})
  }
)

const emit = defineEmits<{
  (e: 'update:model-value', arg1: string | Array<string> | object | Array<object> | number | Array<number> | undefined): void
}>()

// todo: trying this to see if it will blur the input field when the menu is open
const selectMenu = ref()

const isClearable = computed(() => props.clearable && props.modelValue)

function onOpen() {
  if (isClearable.value) {
    emit('update:model-value', undefined)
    return
  }

  if (!props.noFocus) {
    return
  }

  const inputField = document.querySelector<HTMLInputElement>('input[id^="headlessui-combobox-input-"]')
  if (inputField) {
    const timeout = setTimeout(() => {
      inputField?.blur()
      inputField.tabIndex = -1
      clearTimeout(timeout)
    }, 0)
  }
}
</script>
